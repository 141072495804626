import { css } from 'linaria';

import PoppinsRegularWoff from './poppins-regular.woff';
import PoppinsRegularWoff2 from './poppins-regular.woff2';

import PoppinsBlackWoff from './poppins-black.woff';
import PoppinsBlackWoff2 from './poppins-black.woff2';

import ProzaWoff from './proza.woff';
import ProzaWoff2 from './proza.woff2';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Regular'), local('poppins-regular'),
          url(${PoppinsRegularWoff2}) format('woff2'),
          url(${PoppinsRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Black'), local('poppins-black'),
          url(${PoppinsBlackWoff2}) format('woff2'),
          url(${PoppinsBlackWoff}) format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proza Libre';
        src: local('Proza Libre'), local('proza'),
          url(${ProzaWoff}) format('woff2'), url(${ProzaWoff2}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      &.poppins-ready body {
        font-family: 'Poppins', sans-serif;
        line-height: 1;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
