import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import React from 'react';
import { useQuery } from 'react-apollo';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
import { Notifications } from '../Notifications';
import { DesktopMenu } from './CategoryMenu/DesktopMenu';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import SearchButton from './SearchButton';

import { Logotype } from './Logotype';
import { LinkWithExternal } from '../../ui/LinkWithExternal';
import { SearchBar } from './SearchBar';
import { MyPagesLinkContainer } from './CategoryMenu/DesktopMenu';

const Container = styled('header')`
  background-color: #fff;
  position: relative;
  max-width: 95rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.75rem;

  ${theme.above.sm} {
    padding: 0 2rem;
  }

  ${theme.below.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .header-button {
    svg {
      fill: black;
      path {
        fill: black;
      }
    }
  }

  .mobile-icons {
    display: flex;
    .cart-header-button {
      padding-left: 0.75rem;
    }
    .mypages-header-button {
      padding-left: 0.75rem;
    }
  }
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const TopBar = styled.div`
  height: 20px;
  background: ${theme.colors.norraskog.bark};

  > .wrapper {
    ul {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      li {
        margin-left: 1.2rem;
        a {
          font-size: 0.75rem;
          color: ${theme.colors.white};
          font-family: ${theme.fonts.primary};
          text-decoration: none;
          white-space: nowrap;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }

  [data-sticky-header='true'] & {
    padding-top: 10px;
  }
`;

function Preheader(props) {
  if (!props.pages) {
    return null;
  }

  return (
    <TopBar>
      <MaxWidth className="wrapper">
        <ul data-layout="regular">
          {props.pages.map(page => {
            return (
              <li key={page.id}>
                <LinkWithExternal {...page} label={page.name} />
              </li>
            );
          })}
        </ul>
      </MaxWidth>
    </TopBar>
  );
}

export default function Header() {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1,
      topInfoId: 51
    }
  });

  return (
    <>
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <>
              <Preheader pages={result?.data?.topBar?.subPages} />
              <Container as="header">
                <DesktopMenu
                  data={result.data}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                />
              </Container>
            </>
          ) : (
            <>
              <Container as="header">
                <MobileMenu data={result.data} />
                <MobileHeader
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                />
              </Container>
              <SearchBar
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </>
          )
        }
      </Above>
      <Notifications />
      <CartFlyout />
    </>
  );
}

function MobileHeader(props) {
  return (
    <>
      <DrawerTrigger preventOverflow={true} id="menu-drawer">
        {drawer => (
          <MenuButton
            onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
          >
            <div className="header-button">
              <Menu />
            </div>
          </MenuButton>
        )}
      </DrawerTrigger>
      <Logotype />
      <div className="mobile-icons">
        <SearchButton {...props} />
        <MyPagesLinkContainer mobile />
        <CartButton className="cart-header-button" />
      </div>
    </>
  );
}
