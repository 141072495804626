import React from 'react';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { theme } from '../../Theme';
import { Below } from '@jetshop/ui/Breakpoints';

import { SearchBar } from './SearchBar';

const Button = styled('button')`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  :hover {
    color: ${theme.colors.black};
    svg {
      use {
        fill: ${theme.colors.black};
      }
    }
  }
`;

function SearchButton(props) {
  const { setSearchOpen, searchIsOpen, ...rest } = props;
  return (
    <Below breakpoint="lg">
      {matches =>
        matches ? (
          <Button onClick={() => setSearchOpen(state => !state)} {...rest}>
            <Search />
          </Button>
        ) : (
          <>
            <SearchBar {...props} />
            <Button onClick={() => setSearchOpen(state => !state)} {...rest}>
              <Search />
              <span> {t('Search')}</span>
            </Button>
          </>
        )
      }
    </Below>
  );
}

export default SearchButton;
