import React from 'react';
import t from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import CategoryMenuContainer from './CategoryMenuContainer';
import { Logotype } from '../Logotype';

import { ReactComponent as Person } from '../../../../svg/Person.svg';
import SearchButton from '../SearchButton';
import CartButton from '../../../Cart/CartButton';

const Nav = styled('nav')`
  button {
    background: none;
    color: #666;
  }

  .menu-container {
    margin-top: 0;
  }
`;

const Container = styled.div`
  padding: 16px 0;
  position: relative;

  display: flex;
  align-items: center;

  /* Space uneven items evenly */
  > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  .nav-container {
  }

  .logo-container {
    max-width: 110px;
    text-align: center;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
      margin-left: 1rem;
    }

    svg {
      height: 20px;
      width: 20px;
    }

    span {
      font-size: 0.75rem;
    }
  }
`;

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SearchContainer = styled.div`
  display: flex;
`;

export function DesktopMenu({ data, ...searchState }) {
  return (
    <Container>
      <Nav className="nav-container">
        <CategoryMenuContainer queryData={data} />
      </Nav>
      <div className="logo-container">
        <Logotype />
      </div>
      <div className="icon-container">
        <SearchContainer>
          <SearchButton {...searchState} />
        </SearchContainer>
        <MyPagesLinkContainer />
        <CartButton />
      </div>
    </Container>
  );
}

export function MyPagesLinkContainer({ mobile = false }) {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  return (
    <MyPagesLink className="mypages-header-button" to={routes.myPages.path}>
      <Person />
      {!mobile && <span>{loggedIn ? t('My Pages') : t('Log in')}</span>}
    </MyPagesLink>
  );
}
