export const getBales = input => {
  let result = input.match(/\((\d+) balar\)/);

  if (!result || result.length < 2) {
    return '';
  }

  if (result) {
    let number = result[1];
    return number;
  }
};
