import React from 'react';
import { Link } from 'react-router-dom';
// import { Below } from '@jetshop/ui/Breakpoints';
import { css } from 'linaria';
import { ReactComponent as NorraSkog } from './NorraSkog.svg';
import { theme } from '../../Theme';

const logo = css`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    ${theme.above.md} {
      width: 150px;
    }
    width: 100px;
    svg {
      width: 100%;
    }
  }
`;

export function Logotype() {
  return (
    <Link className={logo} to="/">
      <NorraSkog />
    </Link>
  );
}
