import React from 'react';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import t from '@jetshop/intl';
import { useQuery } from '@apollo/react-hooks';

import { LinkWithExternal } from '../../ui/LinkWithExternal';

function FooterLinks() {
  const { data } = useQuery(pagesQuery);

  const pages = data?.pages;

  return pages ? (
    <section>
      {/* <h2>{t('Hitta hjälp')}</h2> */}
      <ul>
        {pages.map(page => {
          const hasSubPage = page.subPages?.length > 0;
          if (!hasSubPage) {
            return (
              <li key={page.id}>
                <LinkWithExternal {...page}>{page.name}</LinkWithExternal>
              </li>
            );
          } else {
            return page.subPages.map(subPage => (
              <li key={page.id}>
                <LinkWithExternal {...subPage}>{subPage.name}</LinkWithExternal>
              </li>
            ));
          }
        })}
      </ul>
    </section>
  ) : null;
}

export default FooterLinks;
