import React from 'react';
import { Link } from 'react-router-dom';

export function LinkWithExternal({ label, children, ...rest }) {
  const {
    externalUrl,
    primaryRoute,
    className,
    onClick = () => {},
    key
  } = rest;

  if (externalUrl?.link && externalUrl?.link.length > 0) {
    return (
      <a
        href={externalUrl.link}
        target={externalUrl.target}
        rel="noreferrer"
        className={className ? className : ''}
        onClick={onClick}
        key={key}
      >
        {children}
        {label}
      </a>
    );
  } else {
    return (
      <Link
        to={
          externalUrl?.link && externalUrl?.link.length > 0
            ? externalUrl?.link
            : primaryRoute?.path
        }
        className={className ? className : ''}
        onClick={onClick}
        key={key}
      >
        {children}
        {label}
      </Link>
    );
  }
}
